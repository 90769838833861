import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { getTestId } from '@shared/utils'

import Avatar from '@components/Avatar'

User.propTypes = {
  data: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    pronouns: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePictureThumbUrl: PropTypes.string,
  }).isRequired,

  /** Can display on a dark background */
  contrast: PropTypes.bool,
}

/**
 * Component to display a User's information including avatar, full name, and pronouns.
 *
 * @example
 * const user = {
 *   fullName: 'Jane Doe',
 *   pronouns: 'She/Her',
 *   firstName: 'Jane',
 *   lastName: 'Doe',
 *   profilePictureThumbUrl: 'https://example.com/avatar.jpg',
 * }
 *
 * return <User data={user} contrast />
 *
 * @param {Object} props - Component props
 * @param {Object} props.data - User data to display
 * @param {string} props.data.fullName - The full name of the user
 * @param {string} [props.data.pronouns] - The pronouns of the user
 * @param {string} props.data.firstName - The first name of the user
 * @param {string} props.data.lastName - The last name of the user
 * @param {string} [props.data.profilePictureThumbUrl] - URL of the user's profile picture thumbnail
 * @param {boolean} [props.contrast=false] - Determines if the text should be styled for contrast (e.g., dark backgrounds)
 * @param {Object} rest - Additional props passed to the component
 */
export default function User({ data, contrast = false, ...rest }) {
  const testId = getTestId(rest, 'user')

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      role="group"
      aria-labelledby={`${testId}-fullName`}
      data-testid={testId}
      {...rest}
    >
      <Avatar user={data} data-testid={`${testId}-avatar`} />
      <Stack>
        <Typography
          id={`${testId}-fullName`}
          sx={{ color: contrast ? 'primary.contrastText' : 'text.primary' }}
          data-testid={`${testId}-fullName`}
        >
          {data.fullName}
        </Typography>
        {data.pronouns && (
          <Typography
            id={`${testId}-pronouns`}
            sx={{ color: contrast ? 'primary.contrastTextSecondary' : 'text.secondary' }}
            data-testid={`${testId}-pronouns`}
          >
            {data.pronouns}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
