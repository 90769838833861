import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  height: Yup.number().required('Height is required').min(0, 'Please enter a valid height'),
  weight: Yup.number().required('Weight is required').min(0, 'Please enter a valid weight'),
  gender: Yup.string().required('Gender is required'),
  bornAs: Yup.string().required('Born as is required'),
  sexuality: Yup.string().required('Sexuality is required'),
  relationship: Yup.string(),
  race: Yup.array().min(1, 'You must choose at least 1 Race').of(Yup.string()).required('Race is required'),
  ethnicity: Yup.string().required('Ethnicity is required'),
  citizen: Yup.boolean(),
})

export const getInitialValues = (user) => {
  return {
    height: user?.height ?? '',
    weight: user?.weight ?? '',
    gender: user?.gender ?? '',
    bornAs: user?.bornAs ?? '',
    sexuality: user?.sexuality ?? '',
    relationship: user?.relationship ?? '',
    race: (user?.race ?? []).filter((v) => Boolean(v)),
    ethnicity: user?.ethnicity ?? '',
  }
}

export const formikToApiData = (data) => {
  return {
    patient: {
      height: data.height,
      weight: data.weight,
      gender: data.gender,
      sex: data.bornAs,
      sexuality: data.sexuality,
      relationship: data.relationship,
      race: data.race,
      ethnicity: data.ethnicity,
    },
  }
}

export const getMissingInfo = (user) => {
  const values = getInitialValues(user)
  const errors = {}
  if (!values.height) {
    errors.height = 'Add your height'
  }
  if (!values.weight) {
    errors.weight = 'Add your weight'
  }
  if (!values.gender) {
    errors.gender = 'Select your gender'
  }
  if (!values.bornAs) {
    errors.bornAs = 'Select your birth gender'
  }
  if (!values.sexuality) {
    errors.sexuality = 'Select your sexuality'
  }
  if (values.race.length === 0) {
    errors.race = 'Select your race'
  }
  if (!values.ethnicity) {
    errors.ethnicity = 'Select your ethnicity'
  }
  return errors
}
