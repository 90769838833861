import { useMemo } from 'react'
import { FormProvider, useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import API from '@shared/services/src/API'
import { handleError, includesOneOfErrorMessages, UserSettingName } from '@shared/utils'

import { useConsents } from '@hooks'
import HookInputControl from '@components/HookInputControl'
import { appName } from '@config'

import { useGiveConsent } from './ConsentDialog.hooks'

/**
 * ConsentDialog component handles the display and submission of user consents.
 *
 * @returns {JSX.Element} The ConsentDialog component.
 */
export default function ConsentDialog() {
  const consents = useConsents()
  const giveConsent = useGiveConsent()

  const noInitials = !consents[UserSettingName.Initials]
  const noHealthDataSharing = !consents[UserSettingName.AllowSharingHealthData]

  const noTOS = !consents[UserSettingName.AcceptTermsOfService]
  const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
  const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        ...(noInitials && {
          initials: Yup.string().required('Enter your initials'),
        }),
        ...(noHealthDataSharing && {
          sharing_data: Yup.boolean().required('Agree to sharing your information').oneOf([true], 'Agree to sharing your information'),
        }),
        ...((noTOS || noPrivacyPolicy || noTelehealthConsent) && {
          agree: Yup.boolean().required('Agree Policy').oneOf([true], 'Agree Policy'),
        }),
      })
    ),
    defaultValues: {
      ...(noInitials && { initials: '' }),
      ...(noHealthDataSharing && { sharing_data: false }),
      ...((noTOS || noPrivacyPolicy || noTelehealthConsent) && { agree: false }),
    },
  })

  const onSubmit = (values) => {
    const data = {
      ...(noInitials && { initials: values.initials }),
      ...(noHealthDataSharing && { sharing_data: values.sharing_data }),
      ...(noTOS && { agree_tos: values.agree }),
      ...(noPrivacyPolicy && { agree_privacy_policy: values.agree }),
      ...(noTelehealthConsent && { telehealth_consent: values.agree }),
    }

    return giveConsent.mutateAsync(data).catch((response) => {
      // Invalid initials
      if (includesOneOfErrorMessages(response.error, ['initials'])) {
        return form.setError('initials', { message: 'Please enter your initials' })
      }
      handleError(response)
    })
  }

  return (
    <Dialog open fullWidth maxWidth="sm" aria-labelledby="consent-dialog-title">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} aria-describedby="consent-dialog-description">
          <DialogContent>
            <Stack spacing={4} direction="column">
              {noInitials && <Consent />}
              {noHealthDataSharing && <DataSharing />}
              {(noTOS || noPrivacyPolicy || noTelehealthConsent) && <TOS consents={consents} />}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              loading={form.formState.isSubmitting}
              disabled={!form.formState.isDirty}
              aria-label="Save consents"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

/**
 * Consent component collects the user's initials for consent to treat.
 *
 * @returns {JSX.Element} The Consent component.
 */
function Consent() {
  return (
    <Stack spacing={1} role="group" aria-labelledby="consent-to-treat-heading" sx={{ alignItems: 'start' }}>
      <Typography variant="h6" id="consent-to-treat-heading">
        Consent to Treat
      </Typography>
      <Typography id="consent-to-treat-description">
        We require all patients to confirm their Consent to Treatment by {appName} providers.
      </Typography>
      <Typography>Initial below to give your consent.</Typography>
      <HookInputControl field="initials">
        <TextField label="Initials" placeholder="Initials" />
      </HookInputControl>
    </Stack>
  )
}

/**
 * DataSharing component collects the user's consent for sharing health data.
 *
 * @returns {JSX.Element} The DataSharing component.
 */
function DataSharing() {
  const { cbo } = useMe()
  const { field, fieldState } = useController({ name: 'sharing_data' })

  return (
    <Stack spacing={1} role="group" aria-labelledby="data-sharing-heading">
      <Typography variant="h6" id="data-sharing-heading">
        Data Sharing
      </Typography>
      <Typography>{cbo?.name}, our community partner, uses your information to waive your provider and lab fees.</Typography>
      <Typography variant="body2">Some insurance providers may restrict pharmacy choices which can impact participation.</Typography>
      <Stack>
        <FormControlLabel
          name="sharing_data"
          label={`I agree to share my information with ${cbo?.name}`}
          control={
            <Checkbox
              checked={field.value}
              onChange={field.onChange}
              inputProps={{ 'aria-label': `Agree to share information with ${cbo?.name}` }}
            />
          }
        />
        {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
      </Stack>
    </Stack>
  )
}

/**
 * TOS component collects the user's agreement to updated policies.
 *
 * @param {object} props - The component props.
 * @param {object} props.consents - The user's consents.
 * @returns {JSX.Element} The TOS component.
 */
function TOS({ consents }) {
  const { field, fieldState } = useController({ name: 'agree' })

  const label = useMemo(() => {
    const noTOS = !consents[UserSettingName.AcceptTermsOfService]
    const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
    const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

    const count = [noTOS, noPrivacyPolicy, noTelehealthConsent].filter(Boolean).length
    const title = [noTelehealthConsent && 'Telehealth Consent', noPrivacyPolicy && 'Privacy Policy', noTOS && 'Terms of Service'].filter(
      Boolean
    )

    return `Our ${joinStrings(title)} ${count > 1 ? 'have' : 'has'} been updated. Please review and accept to continue.`
  }, [consents])

  return (
    <Stack spacing={1} role="group" aria-labelledby="policy-updates-heading">
      <Typography variant="h6" id="policy-updates-heading">
        Policy Updates
      </Typography>
      <Typography>{label}</Typography>
      <Stack>
        <FormControlLabel
          label={<TOSLabel consents={consents} />}
          control={<Checkbox checked={field.value} onChange={field.onChange} inputProps={{ 'aria-label': 'Agree to updated policies' }} />}
        />
        {fieldState.error && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
      </Stack>
    </Stack>
  )
}

/**
 * TOSLabel component displays links to the updated policy documents.
 *
 * @param {object} props - The component props.
 * @param {object} props.consents - The user's consents.
 * @returns {JSX.Element} The TOSLabel component.
 */
function TOSLabel({ consents }) {
  const { data } = useQuery({ queryKey: ['consent-documents'], queryFn: () => API.me.consents() })

  const content = useMemo(() => {
    const noTOS = !consents[UserSettingName.AcceptTermsOfService]
    const noPrivacyPolicy = !consents[UserSettingName.AcceptPrivacyPolicy]
    const noTelehealthConsent = !consents[UserSettingName.AcceptTelehealthConsent]

    const content = [
      noTelehealthConsent && (
        <Link
          component="a"
          href={data?.['telehealth_consent'] || '#'}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Telehealth Consent"
        >
          Telehealth&nbsp;Consent
        </Link>
      ),
      noPrivacyPolicy && (
        <Link
          component="a"
          href={data?.['agree_privacy_policy'] || '#'}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Privacy Policy"
        >
          Privacy&nbsp;Policy
        </Link>
      ),
      noTOS && (
        <Link component="a" href={data?.['agree_tos'] || '#'} target="_blank" rel="noopener noreferrer" aria-label="Terms of Service">
          Terms&nbsp;of&nbsp;Service
        </Link>
      ),
    ].filter(Boolean)

    return joinNodes(content)
  }, [consents, data])

  return (
    <Typography variant="body2">
      I accept the {appName} {content}
    </Typography>
  )
}

/**
 * Joins an array of strings with commas and 'and' before the last item.
 *
 * @param {string[]} arr - The array of strings.
 * @returns {string} The joined string.
 */
function joinStrings(arr) {
  if (arr.length === 1) return arr[0]
  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(', ') + ' and ' + last
}

/**
 * Joins an array of React nodes with commas and 'and' before the last item.
 *
 * @param {React.ReactNode[]} nodes - The array of nodes.
 * @returns {React.ReactNode[]} The joined nodes.
 */
function joinNodes(nodes) {
  return nodes.map((node, index) => {
    if (index === nodes.length - 2) {
      // This is the second-to-last node
      return <span key={index}>{node} and </span>
    } else if (index < nodes.length - 2) {
      // This is not the last or second-to-last node
      return <span key={index}>{node}, </span>
    } else {
      // This is the last node
      return <span key={index}>{node}</span>
    }
  })
}
