import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { pdfjs } from 'react-pdf'
import { RouterProvider } from 'react-router/dom'

import { LicenseInfo } from '@mui/x-date-pickers-pro'

import HoneybadgerProvider from '@shared/providers/src/HoneybadgerProvider'
import { BuildEnv, Logger } from '@shared/utils'

import '@shared/utils/src/dayjsImports'

import router from './pages/Router'

const log = Logger('src/index.js')

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

// -Google Tag Manager---------------------------------------------------------------------
if (import.meta.env.VITE_BUILD_ENV === BuildEnv.Production) {
  const gtmId = import.meta.env.VITE_GTM_ID
  if (!gtmId) {
    console.warn('Google Tag Manager ID was not found!') // do not user Log here, we want this to always show up in the console.
  }

  if (gtmId) {
    TagManager.initialize({
      gtmId: gtmId,
    })
    log.info('Google Tag Manager initialized.')
  }
} else {
  log.info('Google Tag Manager is disabled in local environment.')
}
// -Google Tag Manager---------------------------------------------------------------------

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE)

ReactDOM.createRoot(document.getElementById('root')).render(
  <HoneybadgerProvider>
    <RouterProvider router={router} />
  </HoneybadgerProvider>
)
