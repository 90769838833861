import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

/**
 * DOMMutation component displays a message when the translation feature is unavailable.
 *
 * @returns {JSX.Element} The DOMMutation component.
 */
export default function DOMMutation() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Stack
        spacing={10}
        role="main"
        aria-labelledby="dom-mutation-heading"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          p: 1,
          overflow: 'hidden',
        }}
      >
        <Stack spacing={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center" component="h1" id="dom-mutation-heading">
              Translation Unavailable
            </Typography>
            <Typography variant="h5" align="center">
              We regret to inform you that a technical issue is currently preventing us from fully supporting the Translation feature.
              <br />
              <br />
              To ensure a seamless experience, we recommend temporarily disabling it.
              <br />
              <br />
              We apologize for any inconvenience this may cause and appreciate your understanding as we actively work to resolve this issue.
              <br />
              <br />
              Thank you for your patience.
            </Typography>
          </Stack>
          <Button variant="contained" onClick={() => window.location.reload()} aria-label="Reload the page">
            Reload
          </Button>
        </Stack>
      </Stack>
    </Fade>
  )
}
