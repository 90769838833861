import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router'

import CardActionArea from '@mui/material/CardActionArea'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import { useMe } from '@shared/providers/src/MeProvider'
import { convertPossibleRichTextToPlainText } from '@shared/utils'

import { CloseIcon } from '@icons'

import { styles } from '../Notifications.utils'
import { useGetMessage } from './Messages.hooks'

/**
 * Messages component that listens for new message notifications via PubNub
 * and displays a Snackbar notification allowing the user to join the conversation.
 */
export default function Messages() {
  const testId = 'message-notification'

  const me = useMe()
  const currentPath = useLocation().pathname

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(undefined)

  const getMessage = useGetMessage()

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => setMessage(undefined), 200)
  }

  usePubNub(`user_${me.id}`, async ({ action, attributes }) => {
    if (action !== Channels.NewMessage) return

    const path = window.location.pathname
    const { conversation_id: conversationId, message_id: messageId } = attributes

    // Don't show notification if the user is already in the conversation
    if (path.startsWith(`/app/messages/${conversationId}`)) return

    // Don't show notification if the user is on any visit page
    if (path.startsWith('/app/visits')) return

    const fetchedMessage = await getMessage.mutateAsync({ conversationId, messageId })
    setMessage({ conversationId, ...fetchedMessage })
    setOpen(true)
  })

  const content = message?.message

  return (
    <Snackbar
      key={message?.conversationId}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      role="alert"
      aria-live="assertive"
      aria-label="New Message Notification"
      data-testid={`${testId}-snackbar`}
    >
      <CardActionArea
        component={RouterLink}
        to={`/app/messages/${message?.conversationId}`}
        replace={currentPath.startsWith('/app/messages')}
        onClick={handleClose}
        sx={styles.container}
        data-testid={testId}
        aria-labelledby={`${testId}-title`}
        aria-describedby={`${testId}-description`}
      >
        <Stack direction="row" sx={styles.header}>
          <Typography data-testid={`${testId}-author`} component="h6" id={`${testId}-title`} sx={{ fontWeight: 'bold' }}>
            {message?.user.fullName}
          </Typography>
          <IconButton
            color="inherit"
            edge="end"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
            data-testid={`${testId}-close`}
            aria-label="Close Message Notification"
          >
            <CloseIcon fontSize="inherit" aria-hidden="true" />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Typography sx={styles.message} data-testid={`${testId}-content`} component="p" id={`${testId}-description`}>
            {convertPossibleRichTextToPlainText(content)}
          </Typography>
          <Typography variant="caption" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Click to view
          </Typography>
        </Stack>
      </CardActionArea>
    </Snackbar>
  )
}
