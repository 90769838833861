import { Outlet } from 'react-router'
import { useReadLocalStorage } from 'usehooks-ts'

import InactivityHandler from '@shared/components/src/InactivityHandler'
import KeepSessionAlive, { TrackUserActivity } from '@shared/components/src/KeepSessionAlive'
import Loader from '@shared/components/src/Loader'
import useLoadingState from '@shared/hooks/src/useLoadingState'
import DropdownOptionsProvider, { useDropdownOptions } from '@shared/providers/src/DropdownOptionsProvider'
import FeatureFlagsProvider, { useFeatureFlagsQuery } from '@shared/providers/src/FeatureFlagsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import SettingsProvider, { useSettings } from '@shared/providers/src/SettingsProvider'
import { BuildEnv } from '@shared/utils'

import { useConsentsDone } from '@hooks/useConsents'
import useTreatments, { useIsTreatmentSelected } from '@hooks/useTreatments'
import ConsentDialog from '@pages/Consent'
import TreatmentDialog from '@pages/Treatment'
import Notifications from '@components/Notifications'
import { inactivityTime } from '@config'

import MeProvider from '../MeProvider'

/**
 * This is the wrapper around authenticated part of the app
 * It loads all necessary data and then renders the app routes
 *
 * Put here all providers and handlers that are needed for the app to work
 */
export default function AuthenticatedProviders() {
  const keepAlive = useReadLocalStorage('keepSessionAlive')

  return (
    <DropdownOptionsProvider>
      <SettingsProvider>
        <FeatureFlagsProvider>
          <MeProvider>
            <Splash>
              <Outlet />
              <Notifications />
              <InactivityHandler interval={inactivityTime} />
              <TrackUserActivity />
              {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && keepAlive && <KeepSessionAlive />}
            </Splash>
          </MeProvider>
        </FeatureFlagsProvider>
      </SettingsProvider>
    </DropdownOptionsProvider>
  )
}

function Splash({ children }) {
  const me = useMe()
  const settings = useSettings()
  const options = useDropdownOptions()
  const { flags } = useFeatureFlagsQuery()
  const treatments = useTreatments()

  const loading = useLoadingState(!me || !settings || !flags || !treatments || !options)

  // Load all the necessary data before rendering the app
  if (loading) return <Loader />

  return <AppGuard>{children}</AppGuard>
}

// This is the guard that checks if the user
// has completed the consent and treatment selected
function AppGuard({ children }) {
  const consentsDone = useConsentsDone()
  const isTreatmentSelected = useIsTreatmentSelected()

  if (!consentsDone) return <ConsentDialog />
  if (!isTreatmentSelected) return <TreatmentDialog />

  return children
}
