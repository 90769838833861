import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

import { Content, Description, Page } from '@components/ErrorBase/ErrorBase.components'

/**
 * AppUpdated component displays a message when new updates have been deployed.
 *
 * @returns {JSX.Element} The AppUpdated component.
 */
export default function AppUpdated() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Page role="main" aria-labelledby="app-updated-heading">
        <Content>
          <Description>
            <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center" component="h1" id="app-updated-heading">
              New Updates Deployed!
            </Typography>
            <Typography color="textSecondary" align="center">
              We've made some improvements.
              <br />
              Reload the page to experience the latest features.
            </Typography>
          </Description>
          <Button variant="contained" onClick={() => window.location.reload()} aria-label="Reload the page to apply updates">
            Reload
          </Button>
        </Content>
      </Page>
    </Fade>
  )
}
