import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'

/**
 * ConfirmHIVNoCBOSupport component displays a confirmation dialog for enrolling in HIV+ Care when the Community-Based Organization (CBO) does not support the service.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isOnDemandAvailable - Indicates whether on-demand appointment is available for the selected treatment.
 * @param {function} props.onCancel - Function to call when the cancel button is clicked.
 * @param {function} props.onSchedule - Function to call when the scheduled appointment options selected.
 * @param {function} props.onDemand - Function to call when the on-demand appointment option is selected.
 * @returns {JSX.Element} The ConfirmHIVNoCBOSupport component.
 */
export default function ConfirmHIVNoCBOSupport({ isOnDemandAvailable = false, onCancel, onSchedule, onDemand }) {
  const [handleSchedule, loading] = usePromiseLoading(onSchedule)

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body" aria-labelledby="confirm-hiv-no-cbo-dialog-title" role="dialog">
      <DialogTitle id="confirm-hiv-no-cbo-dialog-title">Enroll in HIV+ Care</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            <b>Overview:</b>
            <br />
            For HIV positive patients, our providers are here to define and provide long-term care.
          </Typography>
          <Typography variant="body2">
            <b>Appointment Scheduling:</b>
            <br />
            Expect a call from our Medical Assistant within the next one to two days to schedule your care appointment.
          </Typography>
          <Typography variant="body2">
            <b>Assessment:</b>
            <br />
            During your visit, you and the Medical Assistant will assess the suitability of the care program based on your health and
            insurance status.
          </Typography>
          <Typography variant="body2">
            <b>If Seeking Prevention:</b>
            <br />
            If you are not HIV positive and are looking for prevention, please go back and choose PrEP option.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          {isOnDemandAvailable && (
            <Button
              onClick={onDemand}
              disabled={loading}
              variant="contained"
              data-testid="hiv-on-demand-confirm"
              aria-label="On Demand appointment for HIV+ Care"
            >
              See Provider Now
            </Button>
          )}
          <Button onClick={handleSchedule} variant="contained" loading={loading} data-testid="hiv-confirm" aria-label="Start HIV+ Care">
            Start HIV+ Care
          </Button>
          <Button onClick={onCancel} disabled={loading} data-testid="back" aria-label="Go back to treatment selection">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
