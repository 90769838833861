import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { getTestId, handleError, includesOneOfErrorMessages, TreatmentType } from '@shared/utils'

import ConfirmHIVCBOSupport from './ConfirmHIVCBOSupport'
import ConfirmHIVNoCBOSupport from './ConfirmHIVNoCBOSupport'
import ConfirmInjectablePrEP from './ConfirmInjectablePrEP'
import ConfirmOnDemand from './ConfirmOnDemand'
import ConfirmOralPrEP from './ConfirmOralPrEP'
import ConfirmSTITesting from './ConfirmSTITesting'
import { useOnDemandAppointmentCreation, usePatientAvailableServiceLines, useTreatmentUpdate } from './TreatmentDialog.hooks'

/**
 * TreatmentDialog component displays treatment options to the user and handles their selection.
 *
 * @returns {JSX.Element} The TreatmentDialog component.
 */
export default function TreatmentDialog() {
  const [confirmOnDemand, setConfirmOnDemand] = useState(false)
  const [selectedTreatment, setSelectedTreatment] = useState()

  const theme = useTheme()
  const navigate = useNavigate()
  const options = useLookup(Lookup.ProviderTypes)
  const availableServiceLines = usePatientAvailableServiceLines()

  const updateTreatment = useTreatmentUpdate()
  const create = useOnDemandAppointmentCreation()

  const handleScheduledAppointment = (props = {}) => {
    return updateTreatment
      .mutateAsync({
        active: true,
        provider_type_id: options[selectedTreatment],
        ...props,
      })
      .catch(handleError)
  }

  const handleOnDemandAppointment = (props = {}) => {
    return create
      .mutateAsync({
        provider_type_id: options[selectedTreatment],
        ...props,
      })
      .then((onDemandLog) => navigate(`/app/on-demand/${onDemandLog.id}`))
      .catch((e) => {
        setConfirmOnDemand(false)
        if (includesOneOfErrorMessages(e, ["The patient's CBO cannot schedule on demand appointments at the moment"])) {
          toast.error('We apologize, but there are no available providers right now')
        } else {
          handleError(e)
        }
      })
  }

  const isSupported = useCallback(
    (type) => {
      const names = availableServiceLines?.map((s) => s.name)
      return names?.includes(type)
    },
    [availableServiceLines]
  )

  const SelectTreatment = useMemo(() => {
    if (selectedTreatment === TreatmentType.OralPrEP) {
      return ConfirmOralPrEP
    }

    if (selectedTreatment === TreatmentType.InjectablePrEP) {
      return ConfirmInjectablePrEP
    }

    if (selectedTreatment === TreatmentType.HIV) {
      return isSupported(TreatmentType.HIV) ? ConfirmHIVCBOSupport : ConfirmHIVNoCBOSupport
    }

    if (selectedTreatment === TreatmentType.STITesting) {
      return ConfirmSTITesting
    }
  }, [selectedTreatment, isSupported])

  if (confirmOnDemand) {
    return (
      <ConfirmOnDemand
        selectedTreatment={selectedTreatment}
        onCancel={() => setConfirmOnDemand(false)}
        onConfirm={handleOnDemandAppointment}
      />
    )
  }

  if (selectedTreatment) {
    return (
      <SelectTreatment
        isOnDemandAvailable={availableServiceLines.find((s) => s.name === selectedTreatment).onDemand}
        onSchedule={handleScheduledAppointment}
        onDemand={() => setConfirmOnDemand(true)}
        onCancel={() => setSelectedTreatment()}
      />
    )
  }

  return (
    <Dialog
      open={Boolean(availableServiceLines)}
      fullWidth
      maxWidth="xs"
      scroll="body"
      aria-labelledby="treatment-dialog-title"
      role="dialog"
    >
      <DialogTitle id="treatment-dialog-title">Welcome to {theme.app.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography sx={{ color: 'text.secondary' }}>What can we help you with today?</Typography>
          <Stack spacing={2} role="group" aria-label="Treatment options" sx={{ py: 2 }}>
            {isSupported(TreatmentType.OralPrEP) && (
              <TreatmentButton
                label="Get Oral PrEP"
                onClick={() => setSelectedTreatment(TreatmentType.OralPrEP)}
                data-testid="oral-prep-treatment-button"
              >
                HIV prevention with a daily oral pill. Quarterly HIV/STI lab testing at home or in-person is included.
              </TreatmentButton>
            )}
            {isSupported(TreatmentType.STITesting) && (
              <TreatmentButton
                label="Get Tested for STIs"
                onClick={() => setSelectedTreatment(TreatmentType.STITesting)}
                data-testid="sti-testing-treatment-button"
              >
                For patients seeking STI testing only.
              </TreatmentButton>
            )}
            {isSupported(TreatmentType.InjectablePrEP) && (
              <TreatmentButton
                label="Get Injectable PrEP"
                onClick={() => setSelectedTreatment(TreatmentType.InjectablePrEP)}
                data-testid="injectable-prep-treatment-button"
              >
                HIV prevention with a shot every two months, in-person shot (no pills). Bi-monthly HIV/STI testing at home or in-person
                included.
              </TreatmentButton>
            )}
            {isSupported(TreatmentType.HIV) && (
              <TreatmentButton label="HIV+ Care" onClick={() => setSelectedTreatment(TreatmentType.HIV)} data-testid="hiv-treatment-button">
                For people living with HIV
              </TreatmentButton>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

/**
 * TreatmentButton component displays a button with a label and description.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label of the button.
 * @param {function} props.onClick - Function to call when the button is clicked.
 * @param {React.ReactNode} props.children - The description text.
 * @returns {JSX.Element} The TreatmentButton component.
 */
function TreatmentButton({ label, onClick, children, ...rest }) {
  const testId = getTestId(rest)

  return (
    <Stack spacing={1}>
      <Button fullWidth variant="outlined" onClick={onClick} data-testid={testId} sx={{ px: 0 }} aria-label={label}>
        {label}
      </Button>
      <Typography variant="body2">{children}</Typography>
    </Stack>
  )
}
