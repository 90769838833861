import { Toaster as ToasterHot } from 'react-hot-toast'

import useTheme from '@mui/styles/useTheme'

export default function Toaster() {
  const theme = useTheme()

  return (
    <ToasterHot
      position="top-center"
      toastOptions={{
        duration: 5000,
        error: {
          style: {
            color: theme.palette.text.primary,
            background: theme.palette.error.extraLight,
            border: '1px solid',
            borderColor: theme.palette.error.main,
            borderRadius: '4rem',
            fontSize: '1rem',
            padding: '1rem',
            fontWeight: theme.typography.fontWeightMedium,
            fontFamily: 'proxima-nova, sans-serif',
          },
          iconTheme: {
            primary: theme.palette.error.main,
            secondary: theme.palette.error.constrastText,
          },
        },
        success: {
          style: {
            color: theme.palette.text.primary,
            background: theme.palette.secondary.extraLight,
            border: '1px solid',
            borderColor: theme.palette.secondary.main,
            borderRadius: '4rem',
            fontSize: '1rem',
            padding: '1rem',
            fontWeight: theme.typography.fontWeightMedium,
            fontFamily: 'proxima-nova, sans-serif',
          },
          iconTheme: {
            primary: theme.palette.secondary.main,
            secondary: theme.palette.secondary.constrastText,
          },
        },
      }}
    />
  )
}
