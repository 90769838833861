import toast from 'react-hot-toast'
import { EditorContent, useEditor } from '@tiptap/react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

import { useSendMessage } from '@shared/messaging/src/hooks'
import { clipboardTextParser, richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { useMe } from '@shared/providers/src/MeProvider'
import { getTestId } from '@shared/utils'

import { useUserOutOfOfficeListener } from '@hooks'
import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { extensions } from '@components/RichText'
import User from '@components/User'

MessageProvider.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called after any action or the dialog is closed*/
  onClose: PropTypes.func.isRequired,
}

/**
 * Display a dialog to send a message to message provider.
 * This handles the api POST to send the message.
 */
export default function MessageProvider({ open, onClose, ...rest }) {
  const testId = getTestId(rest, 'message-provider')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ 'data-testid': testId }}
      aria-labelledby={`${testId}-title`}
      aria-describedby={`${testId}-description`}
      role="dialog"
      aria-modal="true"
    >
      <Content onClose={onClose} data-testid={testId} />
    </Dialog>
  )
}

function Content({ onClose, ...rest }) {
  const me = useMe()
  const provider = me.provider
  const userId = provider?.userId
  const testId = getTestId(rest)

  const editor = useEditor({
    extensions,
    autofocus: 'end',
    editorProps: { clipboardTextParser },
  })

  const sendMessage = useSendMessage({ userId })

  const handleSendMessage = () => {
    const message = editor.getJSON()
    return sendMessage
      .mutateAsync({ message: JSON.stringify(message) })
      .then(() => toast.success('Message sent to your provider'))
      .then(() => onClose())
  }

  useUserOutOfOfficeListener(provider)

  if (!provider) {
    return (
      <>
        <DialogTitle id={`${testId}-title`}>Provider not found</DialogTitle>

        <DialogContent id={`${testId}-description`}>
          We are sorry but you have not been assigned a provider yet. Please contact customer support for more information.
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="text" data-testid={`${testId}-close`} aria-label="Close dialog">
            Close
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <DialogTitle id={`${testId}-title`}>Message My Provider</DialogTitle>
      <DialogContent id={`${testId}-description`}>
        <Stack spacing={1}>
          <User data={provider} />
          <Box
            sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 100, maxHeight: 300 } }]}
            aria-label="Message content editor"
            role="textbox"
          >
            <EditorContent editor={editor} />
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="text" data-testid={`${testId}-cancel`} aria-label="Cancel sending message">
          Cancel
        </Button>
        <Button
          loading={sendMessage.isPending}
          onClick={handleSendMessage}
          variant="contained"
          data-testid={`${testId}-send`}
          disabled={editor.isEmpty}
          aria-label="Send message to provider"
        >
          Send
        </Button>
      </DialogActions>
    </>
  )
}
