import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import QS from 'query-string'
import { QueryParamProvider } from 'use-query-params'

import ReactRouterAdapter from '@shared/components/src/ReactRouterAdapter'
import QueryClientProvider from '@shared/providers/src/QueryClientProvider'
import { BuildEnv } from '@shared/utils'

import AxiosErrorHandler from '@providers/AxiosErrorHandler'
import LocalesProvider from '@providers/LocalesProvider'
import ThemeProvider from '@providers/ThemeProvider'
import WhiteLabeling from '@providers/WhiteLabeling'
import GoogleTranslate from '@components/GoogleTranslate'

import Error from '../Misc/Error'

export default function Root() {
  return (
    <QueryParamProvider adapter={ReactRouterAdapter} options={{ searchStringToObject: QS.parse, objectToSearchString: QS.stringify }}>
      <ThemeProvider>
        <AxiosErrorHandler>
          <QueryClientProvider>
            <LocalesProvider>
              <ErrorBoundary FallbackComponent={Error}>
                <WhiteLabeling>
                  <Outlet />
                  <GoogleTranslate />
                  {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && <ReactQueryDevtools initialIsOpen={false} />}
                </WhiteLabeling>
              </ErrorBoundary>
            </LocalesProvider>
          </QueryClientProvider>
        </AxiosErrorHandler>
      </ThemeProvider>
    </QueryParamProvider>
  )
}
