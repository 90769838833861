import { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import { useReadLocalStorage } from 'usehooks-ts'

import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { useMe } from '@shared/providers/src/MeProvider'
import { AuthUtils, BuildEnv } from '@shared/utils'

import { useProfileMissingErrors } from '@pages/Account/Account.hooks'
import { LogoIcon, MenuIcon, ProvidedByQCareIcon } from '@icons'
import Avatar from '@components/Avatar'
import Dialog from '@components/Dialog'

export default function Header({ hideMenu = false, position = 'fixed' }) {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })

  return (
    <>
      <AppBar elevation={trigger ? 6 : 0} position={position} component="header" role="banner" aria-label="Application Header">
        <Toolbar>
          <LogoIcon />
          <Box sx={{ flexGrow: 1 }} />
          {!hideMenu && <Menu />}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

/**
 * Menu component that displays user options in a popover.
 */
function Menu() {
  const me = useMe()

  const [anchorEl, setAnchorEl] = useState(null)

  const { data } = useProfileMissingErrors()

  const count = useMemo(() => {
    return Object.values(data).reduce((sum, c) => sum + c, 0)
  }, [data])

  const handleClose = () => setAnchorEl(null)

  return (
    <Wrapper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <List disablePadding role="menu" aria-label="Main navigation">
        <ListHeader />

        <Divider />

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/account"
            onClick={handleClose}
            data-testid="account-menu-item"
            aria-label="Navigate to Account settings"
            role="menuitem"
          >
            <ListItemText primary="Account" />
            <Badge
              color="error"
              badgeContent={count}
              max={99}
              data-testid="field-errors-count-badge"
              aria-label={count > 0 ? `${count} profile fields have errors` : 'All profile fields are complete'}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/messages"
            onClick={handleClose}
            data-testid="messages-menu-item"
            aria-label="Navigate to Messages"
            role="menuitem"
          >
            <ListItemText primary="Messages" />
            <Badge
              color="primary"
              badgeContent={me.totalUnreadMessageCount}
              max={99}
              data-testid="unread-messages-count-badge"
              aria-label={me.totalUnreadMessageCount > 0 ? `${me.totalUnreadMessageCount} unread messages` : 'No unread messages'}
            />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/labs"
            onClick={handleClose}
            data-testid="labs-menu-item"
            aria-label="Navigate to Lab work"
            role="menuitem"
          >
            <ListItemText primary="Lab work" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/prescriptions"
            onClick={handleClose}
            data-testid="rx-menu-item"
            aria-label="Navigate to Prescriptions"
            role="menuitem"
          >
            <ListItemText primary="Prescriptions" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/appointments"
            onClick={handleClose}
            data-testid="appointments-menu-item"
            aria-label="Navigate to Appointments"
            role="menuitem"
          >
            <ListItemText primary="Appointments" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/documents"
            onClick={handleClose}
            data-testid="documents-menu-item"
            aria-label="Navigate to Documents"
            role="menuitem"
          >
            <ListItemText primary="Documents" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/app/assessments"
            onClick={handleClose}
            data-testid="assessments-menu-item"
            aria-label="Navigate to Assessments"
            role="menuitem"
          >
            <ListItemText primary="Assessments" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem disablePadding>
          <ListItemButton
            id="faq-menu-item"
            component={RouterLink}
            to="/app/faq"
            onClick={handleClose}
            data-testid="faq-menu-item"
            aria-label="Navigate to FAQ"
            role="menuitem"
          >
            <ListItemText primary="FAQ" />
          </ListItemButton>
        </ListItem>

        <MessageSupportItem onClose={handleClose} />

        <Logout />

        {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={`${import.meta.env.VITE_URL}/environment`}
                onClick={handleClose}
                target="_blank"
                rel="noopener"
                aria-label="View Environment (non-production)"
                role="menuitem"
              >
                <ListItemText primary="Environment (non-prod)" />
              </ListItemButton>
            </ListItem>
          </>
        )}

        <Divider />

        <PoweredByQCare />
      </List>
    </Wrapper>
  )
}

/**
 * Wrapper component that manages the popover state
 */
function Wrapper({ anchorEl, setAnchorEl, children }) {
  const me = useMe()
  const { data } = useProfileMissingErrors()
  const introTour = useReadLocalStorage('intro-tour')

  const notificationsCount = useMemo(() => {
    const profileMissingItemsCount = Object.values(data).reduce((sum, c) => sum + c, 0)
    return profileMissingItemsCount + me.totalUnreadMessageCount
  }, [data, me.totalUnreadMessageCount])

  useEffect(() => {
    // Close the menu if the introduction tour has been passed
    if (introTour?.passed) {
      setAnchorEl(null)
    }
  }, [introTour?.passed, setAnchorEl])

  return (
    <>
      <IconButton
        id="main-menu-button"
        ref={anchorEl}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large"
        color="inherit"
        edge="end"
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}
        data-testid="main-menu-button"
        aria-controls={anchorEl ? 'main-menu' : undefined}
        aria-haspopup="menu"
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-label="Open main menu"
      >
        <Badge
          color="error"
          badgeContent={notificationsCount}
          max={99}
          data-testid="notifications-count-badge"
          aria-label={`${notificationsCount} new notifications`}
        >
          <MenuIcon fontSize="inherit" aria-hidden="true" />
        </Badge>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            id: 'main-menu',
            role: 'menu',
            'aria-label': 'Main menu',
            sx: { maxWidth: 350 },
          },
        }}
      >
        {children}
      </Popover>
    </>
  )
}

function ListHeader() {
  const me = useMe()

  return (
    <ListItem>
      <Stack sx={{ alignItems: 'center', wordBreak: 'break-word', textAlign: 'center' }}>
        <Avatar user={me} size="lg" variant="rounded" sx={{ my: 1 }} />
        <Typography
          variant="subtitle2"
          data-testid="menu-patient-fullName"
          aria-label={`Patient name: ${me.fullChosenName}`}
          sx={{ color: 'text.primary' }}
        >
          {me.fullChosenName}
        </Typography>
        <Typography color="textSecondary" variant="subtitle2" data-testid="menu-patient-email" aria-label={`Patient email: ${me.email}`}>
          {me.email}
        </Typography>
      </Stack>
    </ListItem>
  )
}

function MessageSupportItem({ onClose }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            setOpen(true)
            if (onClose) onClose()
          }}
          data-testid="message-support-menu-item"
          aria-label="Contact support"
          role="menuitem"
        >
          <ListItemText primary="Contact support" />
        </ListItemButton>
      </ListItem>
      <Dialog.MessageSupport open={open} onClose={() => setOpen(false)} />
    </>
  )
}

function Logout() {
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => AuthUtils.logout()}
        data-testid="logout-menu-item"
        aria-label="Log out of the application"
        role="menuitem"
      >
        <ListItemText primary="Log out" />
      </ListItemButton>
    </ListItem>
  )
}

function PoweredByQCare() {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={import.meta.env.VITE_LANDING_PAGE}
        data-testid="landing-link"
        aria-label="Powered by QCare"
        role="menuitem"
        sx={{ justifyContent: 'center', p: 1 }}
      >
        <ProvidedByQCareIcon aria-hidden="true" />
      </ListItemButton>
    </ListItem>
  )
}
