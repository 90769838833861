import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { TreatmentType } from '@shared/utils'

export default function ConfirmOnDemand({ selectedTreatment, onCancel, onConfirm }) {
  const me = useMe()
  const reachLATeamMembers = useLookup(Lookup.ReactLATeamMembers)

  const isReachLA = me.cbo?.name === import.meta.env.VITE_REACH_LA_CBO_NAME
  const isPrEP = [TreatmentType.OralPrEP, TreatmentType.InjectablePrEP].includes(selectedTreatment)
  const canRefer = isReachLA && isPrEP

  const form = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        initials: Yup.string().required('Required').max(3, 'Maximum 3 characters'),
        referredBy: Yup.string(),
        referredByOther: Yup.string().when('referredBy', {
          is: (value) => value === 'Other',
          then: Yup.string().required('Required'),
        }),
      })
    ),
    defaultValues: {
      initials: '',
      referredBy: '',
      referredByOther: '',
    },
  })

  const onSubmit = (values) => {
    return onConfirm({
      initials: values.initials,
      ...(canRefer && {
        referred_by: values.referredBy === 'Other' ? values.referredByOther : values.referredBy,
      }),
    })
  }

  const isSubmitting = form.formState.isSubmitting
  const referredBy = form.watch('referredBy')

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body" aria-labelledby="confirm-on-demand-appointment-dialog-title" role="dialog">
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle id="confirm-on-demand-appointment-dialog-title">Proceed with On Demand Appointment</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              An on-demand appointment is a virtual visit you can request at any time. However, it may take a little while for a provider to
              accept the appointment and start your visit. Thank you for your patience as we work to connect you as quickly as possible.
            </Typography>

            <Typography variant="caption">This appointment typically takes about 20 minutes once it begins.</Typography>

            <Stack spacing={1}>
              <Typography>We require your consent for the exam and possible treatment.</Typography>
              <Controller
                name="initials"
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    label="Initials"
                    autoComplete="off"
                    variant="standard"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    disabled={isSubmitting}
                    data-testid="appt-initials"
                    slotProps={{
                      htmlInput: { maxLength: 3 },
                    }}
                  />
                )}
              />
            </Stack>

            {canRefer && (
              <Stack spacing={1}>
                <Typography>Please select the team member who referred you.</Typography>
                <Controller
                  name="referredBy"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      label="Referred By"
                      variant="standard"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={isSubmitting}
                      data-testid="appt-referred-by"
                    >
                      {reachLATeamMembers.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Collapse in={referredBy === 'Other'}>
                  <Controller
                    name="referredByOther"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        label="Details"
                        variant="standard"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                        disabled={isSubmitting}
                        data-testid="appt-referred-by-other"
                      />
                    )}
                  />
                </Collapse>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pb: 3, px: 3 }}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Button
              type="submit"
              loading={isSubmitting}
              variant="contained"
              data-testid="on-demand-confirm"
              aria-label="Continue with On Demand appointment"
            >
              Proceed
            </Button>
            <Button onClick={onCancel} disabled={isSubmitting} data-testid="back" aria-label="Go back to appointment type selection">
              Go Back
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}
