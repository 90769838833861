import { Link as RouterLink } from 'react-router'

import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'

import LogoPride from '@assets/logo/logo-pride.png'

/**
 * Logo component
 *
 * @param {boolean} isLink - If true, the logo is wrapped in a link to the home page.
 * @param {object} props - Additional props to pass to the img element.
 */
export default function Logo({ isLink = true, ...props }) {
  const theme = useTheme()

  const currentMonth = new Date().getMonth() // Months are zero-based (0 = January)
  const isPrideMonth = currentMonth === 5 // June is the 6th month (index 5)
  const logoSrc = isPrideMonth ? LogoPride : theme.app.logo.large

  const content = <img src={logoSrc} alt="" aria-hidden="true" style={{ maxHeight: 60, maxWidth: 200 }} {...props} />

  if (isLink) {
    return (
      <Link component={RouterLink} to="/app" aria-label="Home" data-testid="logo-home-link" sx={{ height: '100%', width: '100%' }}>
        {content}
      </Link>
    )
  }

  return content
}
