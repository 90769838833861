import React from 'react'

import Box from '@mui/material/Box'

import Confirmation from './Confirmation/Confirmation'
import MessageCareTeam from './MessageCareTeam/MessageCareTeam'
import MessageProvider from './MessageProvider/MessageProvider'
import MessageSupport from './MessageSupport/MessageSupport'

/**
 * Wraps the different kind of Dialogs under the same component
 *
 * @example
 * Confirmation Dialog That has Reject/Confirm Buttons
 * <Dialog.Confirmation .... />
 */
export default function Dialog() {
  return <Box />
}

Dialog.Confirmation = Confirmation
Dialog.MessageSupport = MessageSupport
Dialog.MessageCareTeam = MessageCareTeam
Dialog.MessageProvider = MessageProvider
