import { Outlet } from 'react-router'

import Container from '@mui/material/Container'

const styles = {
  container: {
    textAlign: 'center',
    pt: 6,
    pb: 1,
    px: {
      xs: 4,
      sm: 0,
    },
  },
}
export default function AuthBase({ children }) {
  return (
    <Container maxWidth="xs" sx={styles.container}>
      {children || <Outlet />}
    </Container>
  )
}
