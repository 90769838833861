import { useMemo } from 'react'

import { createTheme, responsiveFontSizes, useTheme } from '@mui/material/styles'

import { baseTheme } from '../../theme/baseTheme'
import { lightTheme } from '../../theme/lightTheme'

export function mapPalette(data) {
  const palette = data?.palette || {}
  return {
    primary: palette.primary,
    secondary: palette.secondary,
    error: palette.error,
    info: palette.info,
    action: palette.action,
    text: palette.text,
    divider: palette.other.divider,
    background: {
      backdrop: palette.other.backdropOverlay,
    },
  }
}

export function mapApp(data) {
  return {
    default: data?.app?.default,
    name: data?.app?.name,
    logo: data?.app?.logo,
  }
}

export const themeUpdater = (theme) => {
  let updatedTheme = createTheme(
    baseTheme({ typography: theme.typography, app: mapApp(theme) }),
    lightTheme({ palette: mapPalette(theme) })
  )

  updatedTheme = responsiveFontSizes(updatedTheme)

  return updatedTheme
}

/** Returns manifest according to white labeling data */
export const useManifest = () => {
  const theme = useTheme()

  return useMemo(() => {
    const content = encodeURIComponent(
      JSON.stringify({
        name: theme.app.name,
        short_name: theme.app.name,
        start_url: import.meta.env.VITE_URL,
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#ffffff',
        icons: [
          {
            src: theme.app.default ? `${import.meta.env.VITE_URL}/logo-192x192.png` : theme.app.logo.main,
            type: 'image/png',
            sizes: '192x192',
          },
        ],
      })
    )
    return 'data:application/manifest+json,' + content
  }, [theme.app.default, theme.app.logo.main, theme.app.name])
}
