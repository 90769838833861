import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

import { getTestId } from '@shared/utils'

import LinearProgress from '@components/LinearProgress'

Confirmation.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Title of the dialog */
  title: PropTypes.string.isRequired,

  /** Description of the dialog */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Label of 'reject' button */
  rejectLabel: PropTypes.string,

  /** Label of 'confirm' button */
  confirmLabel: PropTypes.string.isRequired,

  /** Called after the reject button is clicked */
  onReject: PropTypes.func.isRequired,

  /** Called after the confirm button is clicked */
  onConfirm: PropTypes.func.isRequired,

  /* Can show linear progress on top */
  loading: PropTypes.bool,

  /* Align the text */
  align: PropTypes.string,
}

export default function Confirmation({
  open,
  title,
  description,
  rejectLabel = 'Cancel',
  confirmLabel,
  onReject,
  onConfirm,
  loading = false,
  align = 'left',
  ...rest
}) {
  const testId = getTestId(rest, 'confirmation-dialog')

  return (
    <Dialog
      open={open}
      onClose={onReject}
      fullWidth
      maxWidth="sm"
      PaperProps={{ 'data-testid': testId }}
      sx={{ textAlign: align }}
      aria-labelledby={`${testId}-title`}
      aria-describedby={`${testId}-description`}
      role="dialog"
      aria-modal="true"
    >
      <LinearProgress loading={loading} />
      <Box sx={{ px: 3, py: 2 }}>
        {typeof title === 'string' ? (
          <Typography variant="h6" data-testid={`${testId}-title`} id={`${testId}-title`}>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      <Box sx={{ px: 3, py: 1, whiteSpace: 'pre-line' }}>
        {typeof description === 'string' ? (
          <Typography variant="body1" data-testid={`${testId}-description`} id={`${testId}-description`}>
            {description}
          </Typography>
        ) : (
          description
        )}
      </Box>
      <DialogActions>
        <Button onClick={onReject} variant="outlined" data-testid={`${testId}-reject`} disabled={loading} aria-label={rejectLabel}>
          {rejectLabel}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          data-testid={`${testId}-confirm`}
          loading={loading}
          autoFocus
          aria-label={confirmLabel}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
