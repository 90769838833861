import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { LogoIcon } from '@icons'

/**
 * Header component that displays the application logo.
 * The logo is decorative and hidden from assistive technologies.
 */
export default function Header() {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })

  return (
    <>
      <AppBar elevation={trigger ? 6 : 0} component="header" role="banner" aria-label="Application Header">
        <Toolbar>
          <LogoIcon isLink={false} />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}
