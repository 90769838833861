import React, { useContext, useState } from 'react'
import ModalProvider from 'mui-modal-provider'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider as ThemeProviderMUI } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'

import Toaster from '@components/Toast'

import { baseTheme } from '../../theme/baseTheme'
import { lightTheme } from '../../theme/lightTheme'

const ThemeContext = React.createContext()

export default function ThemeProvider({ children }) {
  const state = useState(() => {
    let theme = createTheme(baseTheme(), lightTheme())
    theme = responsiveFontSizes(theme)
    return theme
  })
  const [theme] = state

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderMUI theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" toastOptions={{ duration: 5000 }} />
          <ModalProvider>
            <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
          </ModalProvider>
        </ThemeProviderMUI>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export function useTheme() {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
