import { Outlet } from 'react-router'

import Transitions from '@components/Transitions'

import AuthorizedLayout from './AuthorizedLayout'
import GuestLayout from './GuestLayout'

/**
 * Generic Layout component that switches between Guest and Authorized layouts.
 * Wraps content with transition effects for smooth navigation.
 */
export default function Layout({ guest = false, children }) {
  const LayoutComponent = guest ? GuestLayout : AuthorizedLayout

  return (
    <LayoutComponent>
      <Transitions in type="fade">
        {children || <Outlet />}
      </Transitions>
    </LayoutComponent>
  )
}
