import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { useEffectOnce } from 'usehooks-ts'

import Loader from '@shared/components/src/Loader'
import API from '@shared/services/src/API'

import { useTheme } from '@providers/ThemeProvider'
import { useThemeArgs } from '@providers/WhiteLabeling/WhiteLabeling.hooks'

import { themeUpdater, useManifest } from './WhiteLabeling.utils'

const WhiteLabelingContext = React.createContext()

/**
 * White labeling feature is used to customize the app for different clients.
 * This component wraps the whole app and gets the theme from the BE to use it.
 * Until the theme is fetched, the app is not rendered to avoid flickering.
 */
export default function WhiteLabeling({ children }) {
  const path = useLocation().pathname

  const [theme, setTheme] = useTheme()
  const manifest = useManifest()
  const { fa, token, theme_id } = useThemeArgs()

  const getTheme = useMutation({
    mutationFn: (query) => API.theme.details(query),
    onSuccess: ({ attributes }) => {
      // Map BE theme response to FE object and update it for the whole app
      const updatedTheme = themeUpdater(attributes)
      setTheme(updatedTheme)
    },
  })

  useEffectOnce(() => {
    // if theme_id is present on 'Enrollment' page, get theme by theme_id
    if (path.startsWith('/auth/enrollment') && theme_id) {
      return getTheme.mutate({ theme_id })
    }

    // if theme_id is present on 'Link Sent' page, get theme by theme_id
    if (path.startsWith('/auth/sent') && theme_id) {
      return getTheme.mutate({ theme_id })
    }

    // if fa token is present on 'DOB Verify' page, get theme by fa token
    if (path.startsWith('/auth/verify') && fa) {
      return getTheme.mutate({ token: fa })
    }

    // if token is present on 'Channel Verified' page, get theme by token
    if (path.startsWith('/auth/verified') && token) {
      return getTheme.mutate({ token })
    }

    // If previous conditions are not met, get theme without params.
    // It will return the default theme for guests and can return specific theme for authenticated users
    return getTheme.mutate()
  })

  return (
    <>
      {getTheme.data ? (
        <WhiteLabelingContext.Provider value={{ refetch: getTheme.mutate }}>{children}</WhiteLabelingContext.Provider>
      ) : (
        <Loader />
      )}

      {/* Override index.html tags according to current theme */}
      <Helmet>
        <title>{theme.app.name}</title>
        <meta name="title" content={theme.app.name} />
        <meta
          name="description"
          content={`PrEP is a once-daily pill that is 99% effective at preventing HIV. ${theme.app.name} makes getting PrEP easy with virtual doctor visits, at-home labs, and your prescriptions delivered directly to your door. Do it all online from the comfort of your home.`}
        />

        {/* Font */}
        {theme.typography.url && <link id="custom-font" rel="stylesheet" href={theme.typography.url} />}

        {/* Manifest with modified pictures */}
        <link rel="manifest" href={manifest} />

        {/* Apple icon */}
        <link rel="apple-touch-icon" href={`${theme.app.default ? '/logo-192x192.png' : theme.app.logo.favicon}?v=2`} />

        {/* Favicon */}
        <link rel="icon" href={`${theme.app.default ? '/favicon.png' : theme.app.logo.favicon}?v=2`} sizes="any" />
      </Helmet>
    </>
  )
}

export function useWhiteLabeling() {
  const context = React.useContext(WhiteLabelingContext)
  if (!context) {
    throw new Error('useWhiteLabeling must be used within a WhiteLabelingProvider')
  }
  return context
}
