import { useLayoutEffect, useState } from 'react'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'

import { LanguageIcon } from '@icons'

import styles from './GoogleTranslate.styles'

const googleTranslateElementInit = () => {
  new window.google.translate.TranslateElement(
    {
      pageLanguage: 'en',
      includedLanguages: 'ar,es,fr',
    },
    'google_translate_element'
  )
}

/**
 * Google Widget for translating the entire page content.
 */
export default function GoogleTranslate() {
  const [open, setOpen] = useState(false)

  useLayoutEffect(() => {
    // Prevent the Google Translate script from being loaded multiple times
    const scriptElement = document.getElementById('googleTranslateScript')
    if (!scriptElement) {
      const addScript = document.createElement('script')
      addScript.setAttribute('id', 'googleTranslateScript')
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
      document.body.appendChild(addScript)
      window.googleTranslateElementInit = googleTranslateElementInit
    }
  }, [])

  const handleClickAway = () => {
    if (open) setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper elevation={0} sx={[styles.container, open ? { transform: 'none' } : {}]}>
        <Box id="google_translate_element" sx={styles.select} />
        <Fade in={!open}>
          <Box
            onClick={() => setOpen((o) => !o)}
            sx={styles.tongue}
            role="button"
            aria-label="Toggle language translation"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                setOpen((o) => !o)
              }
            }}
          >
            <LanguageIcon fontSize="small" aria-hidden="true" />
          </Box>
        </Fade>
      </Paper>
    </ClickAwayListener>
  )
}
