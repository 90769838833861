import { Outlet } from 'react-router'

import Header from './Header'

/**
 * Layout component for authorized users.
 * Includes the Header and renders child content or Outlet for nested routes.
 */
export default function AuthorizedLayout({ children }) {
  return (
    <>
      <Header />
      <main>{children || <Outlet />}</main>
    </>
  )
}
