import { useNavigate } from 'react-router'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'

/**
 * ConfirmOralPrEP component displays a confirmation dialog for enrolling in Oral PrEP care.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isOnDemandAvailable - Indicates whether on-demand appointment is available for the selected treatment.
 * @param {function} props.onCancel - Function to call when the cancel button is clicked.
 * @param {function} props.onSchedule - Function to call when the scheduled appointment options selected.
 * @param {function} props.onDemand - Function to call when the on-demand appointment option is selected.
 * @returns {JSX.Element} The ConfirmOralPrEP component.
 */
export default function ConfirmOralPrEP({ isOnDemandAvailable = false, onCancel, onSchedule, onDemand }) {
  const navigate = useNavigate()

  const [handleSchedule, loading] = usePromiseLoading(() => {
    return onSchedule().then(() => navigate('/app/appointments?action=schedule-appointment'))
  })

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body" aria-labelledby="confirm-oral-prep-dialog-title" role="dialog">
      <DialogTitle id="confirm-oral-prep-dialog-title">Enroll in Oral PrEP Care</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: 'bold' }}>PrEP is a medication used to help prevent HIV infections.</Typography>
          <Typography variant="body2">
            <b>Overview:</b>
            <br />
            Initiate your PrEP care with an online provider appointment to assess suitability based on your health and insurance status.
          </Typography>
          <Typography variant="body2">
            <b>Ongoing Care:</b>
            <br />
            Upon approval, follow the prescribed schedule for Oral PrEP. Adhering to this schedule is crucial for optimal effectiveness.
          </Typography>
          <Typography variant="body2">
            <b>Adherence is Key:</b>
            <br />
            Commit to the prescribed schedule. Regular provider follow-ups are essential for monitoring and maintaining your health.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          {isOnDemandAvailable && (
            <Button
              onClick={onDemand}
              disabled={loading}
              variant="contained"
              data-testid="oral-prep-on-demand-confirm"
              aria-label="On Demand appointment for Oral PrEP"
            >
              See Provider Now
            </Button>
          )}
          <Button
            onClick={handleSchedule}
            variant="contained"
            loading={loading}
            data-testid="oral-prep-confirm"
            aria-label="Schedule appointment for Oral PrEP"
          >
            Schedule Future Appointment
          </Button>
          <Button onClick={onCancel} disabled={loading} data-testid="back" aria-label="Go back to treatment selection">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
